

function dumpOptionsInfo() {
  let videoElem = document.getElementById("powerbiCapturePanel");
  let track = videoElem.srcObject.getVideoTracks()[0];
  console.info("Track settings:");
  console.info(JSON.stringify(track.getSettings(), null, 2));
  console.info("Track constraints:");
  console.info(JSON.stringify(track.getConstraints(), null, 2));
}

async function startCapture() {
  const w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    viewportWidth = w.innerWidth || e.clientWidth || g.clientWidth,
    viewportHeight = w.innerHeight || e.clientHeight || g.clientHeight,
    ratio = w.screen.width / w.screen.height; //viewportWidth / viewportHeight,

  const displayMediaOptions = {
    video: {
      aspectRatio: ratio,
      cursor: "never",
      displaySurface: "browser",
      frameRate: 30,
      height: 4320, //viewportHeight,
      logicalSurface: true,
      resizeMode: "crop-and-scale",
      width: Math.ceil(4320 * ratio) //viewportWidth
    },
    audio: false
  };

  try {
    let videoElem = document.getElementById("powerbiCapturePanel");
    videoElem.srcObject = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
    dumpOptionsInfo();
  } catch (err) {
    console.error("Error: " + err);
  }
}

function setContentHintToText() {
  try {
    let videoElem = document.getElementById("powerbiCapturePanel");
    if (videoElem && videoElem.srcObject) {
      videoElem.srcObject.getTracks().forEach(track => 
      {
        if ("contentHint" in track) {
          track.contentHint = 'text';
        }
      });
    }
  } catch (err) {
    console.error("Error: " + err);
  }
}

function stopCapture() {
  let videoElem = document.getElementById("powerbiCapturePanel");
  if (videoElem && videoElem.srcObject) {
    let tracks = videoElem.srcObject.getTracks();
    tracks.forEach(track => track.stop());
    videoElem.srcObject = null;
  }
}
